<template>
  <div>
    <el-form
      :inline="true"
      :model="formSet"
      ref="configData"
      :rules="rules"
      label-position="left"
      :label-width="labelWidth"
      class="demo-form-inline"
    >

      <el-row>
        <el-col :span="colspan" v-for="(item, index) in configData" :key="item.formItemId">
          <el-form-item :label="item.label" :prop="item.prop">
            <template v-if="item.type == 'input'">
              <el-input
                clearable
                v-model="formSet[item.prop]"
                :placeholder="item.placeholder"
                :disabled="item.disabled ? item.disabled : false"
                 @input="handleInput(formSet[item.prop], item.prop)"
              ></el-input>
            </template>
            <template v-if="item.type == 'textarea'">
              <el-input
                type="textarea"
                :rows="item.rowSpan"
                v-model="formSet[item.prop]"
                placeholder="请输入内容">
              </el-input>
            </template>
            <template v-if="item.type == 'colorInput'">
              <el-color-picker v-model="formSet[item.prop]"></el-color-picker>
            </template>
             <template v-if="item.type == 'timedateFormat'">
              <el-date-picker
                v-model="formSet[item.prop]"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </template>
            <template v-if="item.type == 'numberInput'">
              <el-input
                clearable
                type="number"
                v-model.number="formSet[item.prop]"
                :placeholder="item.placeholder"
                :disabled="item.disabled ? item.disabled : false"
                :style="'width: ' + item.width + 'px'"
              ></el-input>
            </template>
            <template v-if="item.type == 'select'">
              <el-select
                clearable
                v-model="formSet[item.prop]"
                :placeholder="item.placeholder"
                :filterable="item.filterable"
                :multiple="item.multiple"
                @focus="handleFocus(index, item.isApi)"
              >
                <template>
                  <el-option
                    :label="optItem.label"
                    :value="optItem.value"
                    v-for="(optItem, index) in item.options"
                    :key="index + 'option_selected'"
                  ></el-option>
                </template>
              </el-select>
            </template>
            <template v-if="item.type == 'timePicker'">
              <el-date-picker
                clearable
                v-model="formSet[item.prop]"
                align="right"
                :type="item.optionType"
                :placeholder="item.placeholder"
                :start-placeholder="item.startPlaceholder"
                :end-placeholder="item.endPlaceholder"
                :picker-options="item.options"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </template>
            <template v-if="item.type == 'threeInput'">
              <el-input
                clearable
                v-model="formSet[item.prop]"
                style="width: 90px; margin-right: 5px"
              ></el-input>
              <!-- @lys -->
              <!-- <el-form-item
                prop="hours"
                :rules="[{ required: true, message: '请输入时', trigger: 'blur'}]"
              >
                <el-input clearable v-model="formSet.hours" style="width: 90px; margin-right: 5px" ></el-input>时
              </el-form-item>
              <el-form-item
                prop="minutes"
                :rules="[{ required: true, message: '请输入分', trigger: 'blur'}]"
              >
                <el-input clearable v-model="formSet.minutes" style="width: 90px; margin-right: 5px" ></el-input>分
              </el-form-item>
              <el-form-item
                prop="seconds"
                :rules="[{ required: true, message: '请输入秒', trigger: 'blur'}]"
              >
                <el-input clearable v-model="formSet.seconds" style="width: 90px; margin-right: 5px" ></el-input>秒
              </el-form-item>-->
            </template>
            <template v-if="item.type == 'multipleBoxes'">
              <div v-for="(items, indexs) in item.configDatas" :key="indexs" style="display: inline-block">
                 <template v-if="items.type == 'timedateFormat'">
                  <el-date-picker
                    v-model="formSet[items.prop]"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                  </el-date-picker>
                </template>
                <template v-if="items.type == 'select'">
                  <el-select
                    clearable
                    v-model="formSet[items.prop]"
                    :placeholder="items.placeholder"
                    :filterable="items.filterable"
                    :multiple="items.multiple"
                    :disabled="items.disabled ? items.disabled : false"
                    @focus="handleFocus(items, items.isApi)"
                    @change="handleChange(item, items.isApi1)"
                    :style="'width: ' + items.width + 'px'"
                  >
                    <template>
                      <el-option
                        :label="optItem.label"
                        :value="optItem.value"
                        v-for="(optItem, index) in items.options"
                        :key="index + 'option_selected'"
                      ></el-option>
                    </template>
                  </el-select>
                </template>
                <template v-if="items.type == 'timePicker'">
                  <el-date-picker
                    clearable
                    v-model="formSet[items.prop]"
                    align="right"
                    :type="items.optionType"
                    :placeholder="items.placeholder"
                    :start-placeholder="items.startPlaceholder"
                    :end-placeholder="items.endPlaceholder"
                    :picker-options="items.options"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :style="'width: ' + items.width + 'px'"
                  ></el-date-picker>
                </template>
                <template v-if="items.type == 'input'">
                  <el-input
                    clearable
                    v-model="formSet[items.prop]"
                    :placeholder="items.placeholder"
                    :disabled="items.disabled ? items.disabled : false"
                    :style="'width: ' + items.width + 'px'"
                  ></el-input>
                </template>
              </div>
            </template>
            <template v-if="item.type == 'cascader'">
              <el-cascader
                :placeholder="item.placeholder"
                v-model="formSet[item.prop]"
                :options="item.options"
                :props="item.defaultProps"
                :node-key="item.node_key"
                filterable
                clearable
              />
            </template>
            <template v-if="item.type == 'uploadAvatar'">
              <el-upload
                class="avatar-uploader"
                :action="'http://dev.kcguoyi.com/service/upload/huawei'"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="(file, fileList) => {handleImport(file, fileList, item)}"
              >
                <img v-if="formSet[item.prop]" :src="formSet[item.prop]" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
            <template v-if="item.type == 'ueditor'">
              <d2-ueditor v-model="formSet[item.prop]"></d2-ueditor>
            </template>
            <template v-if="item.type == 'quill'">
              <d2-quill
                v-model="formSet[item.prop]"
                ref="myQuillEditor"
                @text-change="onEditorBlur"
                @selection-change="onEditorFocus"
                @editor-change="onEditorChange"
              />
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="btnColspan">
          <el-form-item>
            <slot></slot>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { uploadPic } from '@/model/modules/user'
import { Loading } from 'element-ui'
export default {
  props: {
    configData: {
      type: Array
    },
    formSet: {
      type: Object
    },
    colspan: Number,
    btnColspan: Number,
    labelWidth: String,
    rules: Object
  },
  data () {
    return {
      selectOptions: [],
      uploadPath: 'http://dev.kcguoyi.com/service/upload/huawei',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      imageUrl: '',
      content: '',
      editOptions: {}
    }
  },
  watch: {
    selectOptions (val) {}
  },
  methods: {
    handleInput(val, name){
      this.formSet[name] = val.trim()
    },
    onEditorBlur (delta, oldDelta, source) {
      // 失去焦点事件
    },
    onEditorFocus (range, oldRange, source) {
      // 获得焦点事件
    },
    onEditorChange (eventName, ...args) {
      // 内容改变事件
    },
    handleFocus (index, isApi) {
      if (!isApi) {
      } else {
        this.$emit('on-focus-select', { index: index })
      }
    },
    handleChange (indexs, isApi1) {
      if (!isApi1) {
      } else {
        this.$emit('on-change-select', indexs)
      }
    },
    resetForm () {
      this.$nextTick(() => {
        this.$refs.configData.resetFields()
      })
    },
    handleImport (file, fileList, item) {
      var propName = item.prop

      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
      const formdata = new FormData()
      formdata.append('file', file.raw)

      var loading = this.$loading({
        lock: true,
          text: '图片上传中',
      })

      uploadPic(formdata).then((res) => {
        var fileurl =
          URL.createObjectURL(file.raw) || res.data.body[0].filepath

        this.formSet.imgUrl = fileurl;

        this.$emit('on-avatar-success', {
          res: res.data.body[0],
          origin: this.formSet,
          propName: propName
        })

      }).finally(()=>{
        this.$nextTick(()=>{this.$forceUpdate()})
        loading.close();
      })
    }
    // handleAvatarSuccess (res, file) {
    //   this.formSet.fPicUrl = URL.createObjectURL(file.raw) || res[0].filepath
    //   this.$emit('on-avatar-success', { res: res, origin: this.formSet })
    // },
    // beforeAvatarUpload (file) {
    //   // eslint-disable-next-line no-unused-vars
    //   const isJPG = file.type === 'image/jpeg'
    //   // eslint-disable-next-line no-unused-vars
    //   const isLt2M = file.size / 1024 / 1024 < 2
    //   // if (!isLt2M) {
    //   //  this.$message.error('上传头像图片大小不能超过 2MB!');
    //   // }
    //   // return isJPG && isLt2M;
    // }
  }
}
</script>
